
import Vue from 'vue'
import BaseList from '@/components/lists/BaseList.vue'
import ModalForm from '@/components/common/ModalForm.vue'
import LocationForm from '@/components/forms/LocationForm.vue'
import { BtnInterface, BtnEventInterface, BtnEventNameInterface } from '@/lib/interfaces'
import Apiclient from '@/plugins/apiclient'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'TradingPartnerForm',
  components: { BaseList, ModalForm, LocationForm },
  props: {
    buttons: {
      type: Array,
      default: (): Array<BtnInterface> => []
    }
  },
  data () {
    return {
      tab: 0,
      headers: [
        {
          text: 'Store',
          value: 'store',
          sortable: true
        },
        {
          text: 'Address',
          value: 'address',
          sortable: true
        },
        {
          text: 'Store Manager',
          value: 'store_mgr',
          sortable: true
        },
        {
          text: 'Phone',
          value: 'phone',
          sortable: true
        },
        {
          text: 'Fax',
          value: 'fax',
          sortable: true
        },
        {
          text: 'Invoice Email',
          value: 'invoice_email',
          sortable: true
        }
      ],
      recordOptions: [
        {
          icon: 'mdi-pencil',
          label: 'Edit',
          color: 'primary'
        }
      ],
      formButtons: [
        {
          label: 'Cancel',
          id: 'applicationform-button-cancel',
          outlined: true,
          autoClose: true
        },
        {
          label: 'Save',
          id: 'applicationform-button-save'
        }
      ],
      listButtons: [
        {
          icon: 'mdi-plus',
          label: 'New Location',
          id: 'locationlist-button-new'
        }
      ],
      ediMethod: [
        {
          label: 'Secure FTP (SFTP)',
          value: 'sftp'
        },
        {
          label: 'Lightwell VAN',
          value: 'van'
        }
      ],
      rules: {
        required: value => !!value || 'Required.'
      }
    }
  },
  computed: {
    isValid () {
      return this.formData.name?.trim().length > 0 &&
        this.formData.elliot_id?.trim().length > 0 &&
        this.formData.edi_method?.trim().length > 0
    },
    ...mapGetters({
      formData: 'getFormRecord'
    })
  },
  methods: {
    triggerButton (button: BtnInterface) {
      if (button.autoClose) {
        this.hideModal()
      } else {
        if (button.label.toLowerCase() !== 'cancel') {
          if (this.isValid) {
            this.$emit('btnClick', { button, data: this.formData }, this.tab)
          } else {
            alert('Please make sure you have input Trading Partner Name, Elliott ID and have selected the EDI method')
          }
        } else {
          this.$emit('btnClick', { button, data: this.formData }, this.tab)
        }
      }
    },
    showDetails (orderId: string) {
      try {
        this.$router.push({
          name: 'purchase-order-detail',
          params: {
            purchaseOrderId: orderId
          }
        })
      } catch (e) {
        console.error(e)
      }
    },

    btnClick (btnEvent) {
      switch (btnEvent.eventName) {
        case 'new_location': {
          this.clearLocationModalRecord()
          this.setLocationModalFormRecord({ trading_partner_id: this.formData.id })
          this.showModal()
        }
      }
    },

    async optClick (eventInfo: BtnEventNameInterface) {
      if (eventInfo.eventName === 'edit') {
        this.setLocationModalFormRecord(eventInfo.eventData)
        this.showModal()
      }
    },

    async buttonClick (eventData: BtnEventInterface) {
      if (eventData.button.label === 'Save') {
        try {
          if (!eventData.data.id) {
            await Apiclient.post('/vendors/location', eventData.data)
          } else {
            await Apiclient.put(`/vendors/location/${eventData.data.id}`, eventData.data)
          }
          const ref = this.$refs.listing as unknown
          ref.refresh()
          this.hideModal()
        } catch (e) {
          this.setError(e)
        }
      } else if (eventData.button.label === 'Cancel') {
        const ref = this.$refs.listing as unknown
        ref.refresh()
      }
    },

    ...mapActions(['hideModal', 'setError', 'setModalFormRecord', 'setLocationModalFormRecord', 'clearLocationModalRecord', 'clearModalRecord', 'showModal'])
  }
})
