
import Vue from 'vue'
import ApiClient from '@/plugins/apiclient.ts'
import BaseList from '@/components/lists/BaseList.vue'

export default Vue.extend({
  name: 'ElliottFiles',
  components: { BaseList },
  data () {
    return {
      items: [],
      buttons: [
        {
          label: 'Process Pending',
          icon: 'mdi-play'
        },
        {
          label: 'Archive Skipped',
          icon: 'mdi-folder',
          color: 'red',
          outlined: true
        }
      ],
      headers: [
        {
          text: 'Filename',
          value: 'filename',
          sortable: true
        },
        {
          text: 'Status',
          value: 'status',
          sortable: true
        },
        {
          text: 'Purchase Order',
          value: 'po_number',
          sortable: true
        },
        {
          text: 'Parsed On',
          value: 'parsed_on',
          sortable: true
        }
      ],
      recordOptions: [{
        label: 'Validate',
        icon: 'mdi-refresh',
        color: 'primary'
      }, {
        label: 'Archive',
        icon: 'mdi-folder',
        color: 'secondary'
      }, {
        label: 'Download',
        icon: 'mdi-download',
        color: 'secondary'
      }]
    }
  },
  methods: {
    async btnClick (event) {
      if (event.eventName === 'process_pending') {
        await ApiClient.get('/elliott-files/process-pending')
        this.$refs.listing.refresh()
      }

      if (event.eventName === 'archive_skipped') {
        await ApiClient.get('/elliott-files/archive')
        this.$refs.listing.refresh()
      }
    },

    async optClick (event) {
      if (event.eventName.toLowerCase() === 'validate') {
        await ApiClient.get(`/elliott-files/${event.eventData.id}/validate`)
        this.$refs.listing.refresh()
      }

      if (event.eventName.toLowerCase() === 'archive') {
        await ApiClient.get(`/elliott-files/${event.eventData.id}/archive`)
        this.$refs.listing.refresh()
      }

      if (event.eventName.toLowerCase() === 'download') {
        const res = await ApiClient.download(`/elliott-files/${event.eventData.id}/download`)
        const href = URL.createObjectURL(res.data)
        const link = document.createElement('a')
        // const downloadUrl = `/elliott-files/${event.eventData.id}/download`
        link.style = 'display: none'
        link.href = href
        link.download = event.eventData.filename
        link.click()
        link.remove()
        this.$refs.listing.refresh()
      }
    }
  }
})
