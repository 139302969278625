
import Vue from 'vue'
import { BtnInterface } from '@/lib/interfaces'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'ModalForm',
  props: {
    maxWidth: {
      type: String,
      default: '600px'
    },
    title: {
      type: String,
      default: 'Form title'
    }
  },

  data () {
    return {
    }
  },

  computed: {
    ...mapGetters({
      isVisible: 'isModalVisible'
    })
  },

  methods: {
    triggerButton (button: BtnInterface) {
      const label = button.label
      this.$emit(
        label.toLowerCase().replaceAll(' ', '_'),
        button
      )
    },

    ...mapActions(['showModal', 'hideModal'])
  }
})
