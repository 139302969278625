
import Vue from 'vue'
import Apiclient from '@/plugins/apiclient'
import TradingPartnerForm from '@/components/forms/TradingPartnerForm.vue'
import { BtnEventInterface } from '@/lib/interfaces'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'TradingPartnerView',
  components: {
    TradingPartnerForm
  },
  data () {
    return {
      title: 'Trading Partner Information',
      vendorData: null,
      formButtons: [
        {
          label: 'Cancel',
          id: 'vendorform-form-button-cancel',
          outlined: true
        },
        {
          label: 'Save',
          id: 'vendorform-button-save'
        }
      ]
    }
  },
  async mounted () {
    if (parseInt(this.$route.params.vendorId)) {
      const res = await Apiclient.get(`/vendors/${this.$route.params.vendorId}`)
      this.setModalFormRecord(res.data)
    }
  },
  methods: {
    async buttonClick (eventData: BtnEventInterface) {
      if (eventData.button.label.toLowerCase() === 'save') {
        try {
          if (!eventData.data.id) {
            await Apiclient.post('/vendors', eventData.data)
          } else {
            await Apiclient.put(`/vendors/${eventData.data.id}`, eventData.data)
          }
          await this.$router.push({ name: 'trading-partners' })
        } catch (e) {
          this.setError(e)
        }
      }
      if (eventData.button.label.toLowerCase() === 'cancel') {
        await this.$router.push({ name: 'trading-partners' })
      }
    },

    ...mapActions([
      'clearModalRecord',
      'setModalFormRecord',
      'setError'
    ])

  }
})
