
import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  name: 'TakePicture',
  data () {
    return {
      file: null,
      apiResponse: null,
      extension: '',
      url: '',
      processing: false,
      weights: ['best_ckpt_100', 'best_ckpt_150', 'best_ckpt_200', 'best_ckpt_400'],
      weight: 'best_ckpt_200'
    }
  },
  methods: {
    updatePreview () {
      this.processing = false
      this.apiResponse = null
      this.url = URL.createObjectURL(this.file)
      this.extension = this.file.type.split('/')[1]
    },

    async send () {
      this.processing = true
      const res = await axios.post(
        `https://edi-stg.gzanders.com/visual-search/hit-me?w=${this.weight}`,
        { file: this.file },
        {
          headers: {
            'content-type': 'multipart/form-data'
          }
        }
      )
      this.apiResponse = res.data
      this.processing = false
    }
  }
})
