
import Vue from 'vue'
import BaseList from './BaseList.vue'
import Apiclient from '@/plugins/apiclient'
import { BtnEventNameInterface } from '@/lib/interfaces'
import messages from '@/lang/en.json'
import Config from '@/plugins/config'

export default Vue.extend({
  name: 'PurchaseOrdersList',
  components: { BaseList },
  methods: {
    async btnClick (btnEvent) {
      switch (btnEvent.eventName) {
        case 'export_dwh_edi': {
          await Apiclient.post('/purchase-orders/export')
          alert('Export DWH EDI')
        }
      }
    },
    getMessage (key:string): string {
      return messages[key]
    },
    async optClick (eventInfo: BtnEventNameInterface) {
      switch (eventInfo.eventName) {
        case 'download':
          window.open(`${Config.apiUrl}/purchase-orders/${eventInfo.eventData.id}/generate`)
          break
        case 'details':
          try {
            this.$router.push({
              name: 'purchase-order-detail',
              params: {
                purchaseOrderId: eventInfo.eventData.id
              }
            })
          } catch (e) {
            console.error(e)
          }
          break
      }
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'Order Number',
          value: 'number',
          sortable: true
        },
        {
          text: 'Trading Partner',
          value: 'name',
          sortable: true
        },
        {
          text: 'Status',
          value: 'status',
          sortable: true
        },
        {
          text: 'Total Items',
          value: 'total_items',
          sortable: true
        },
        {
          text: 'Updated At',
          value: 'updated_at',
          sortable: true
        },
        {
          text: 'Pending Items Cost',
          value: 'pending',
          sortable: true
        }
      ],
      buttons: [
        {
          icon: 'mdi-folder-arrow-left-right-outline',
          label: 'Export data to DWH',
          id: 'vendorslist-button-new'
        }
      ],
      recordOptions: [
        {
          icon: 'mdi-eye',
          label: 'Details',
          color: 'primary'
        },
        {
          icon: 'mdi-download',
          label: 'Download',
          color: 'primary'
        }
      ]
    }
  }

})
