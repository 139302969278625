<template>
  <trading-partners-list />
</template>

<script>
import TradingPartnersList from '@/components/lists/TradingPartnersList.vue'

export default {
  name: 'VendorsPage',
  components: {
    TradingPartnersList
  }
}
</script>

<style scoped>

</style>
