
import Vue from 'vue'
import Apiclient from '@/plugins/apiclient'
import { BtnInterface } from '@/lib/interfaces'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'ApplicationSettings',
  props: {
    buttons: {
      type: Array,
      default: (): Array<BtnInterface> => []
    }
  },
  data () {
    return {
      tab: 0,
      formButtons: [
        {
          label: 'Cancel',
          id: 'vendorform-button-cancel',
          outlined: true
        },
        {
          label: 'Save',
          id: 'vendorform-button-save'
        }
      ]
    }
  },
  async mounted () {
    const res = await Apiclient.get('settings')
    this.setModalFormRecord(res.data)
    console.log(res.data)
  },
  computed: {
    isValid () {
      if (this.formData.edi_billto_n1 && this.formData.edi_shipto_n1) {
        return this.formData.edi_billto_n1.trim().length > 0 && this.formData.edi_shipto_n1.trim().length > 0
      } else {
        return false
      }
    },
    ...mapGetters({
      formData: 'getFormRecord'
    })
  },
  methods: {
    async triggerButton () {
      const res = await Apiclient.post('settings', this.formData)
      if (res.status === 200) {
        alert('Settings saved successfully')
      } else {
        alert('An error has occurred')
      }
    },
    ...mapActions([
      'hideModal',
      'setModalFormRecord'
    ])
  }
})
