import store from '../store/index'
import cookie from './cookie'
import Apiclient from '@/plugins/apiclient'

class Token {
  async check () : Promise<boolean> {
    const token = cookie.get()
    if (token) {
      const csrf = await Apiclient.get('/csrf-token')
      const res = await Apiclient.post('/auth-token-check', { token }, csrf.data.token)
      if (res.data && res.data.result) {
        await store.dispatch('login', res.data.user)
        return true
      }
    }
    return false
  }

  get () : string {
    const authCookie = document.cookie.split(';').map(i => i.trim()).filter(i => i.startsWith('auth_token='))
    let tokenString = ''
    if (authCookie.length > 0) {
      tokenString = authCookie[0].split('=')[1]
    }
    return tokenString
  }
}

export default new Token()
