interface AppConfig {
  apiKey: string,
  apiUrl: string
}

const appConfig : AppConfig = {
  apiKey: 'mzp204nIZmYazsWl',
  apiUrl: 'https://edi.gzanders.com/api'
}

export default appConfig
