
import Vue from 'vue'
import BaseList from '@/components/lists/BaseList.vue'
import ApiClient from '@/plugins/apiclient.ts'

export default Vue.extend({
  name: 'TranferLog',
  components: { BaseList },
  data () {
    return {
      dialog: false,
      dialog_title: '',
      file_contents: '',
      items: [],
      headers: [
        {
          text: 'Date',
          value: 'executed_at',
          sortable: true
        },
        {
          text: 'Trading Partner',
          value: 'name',
          sortable: true,
          searchable: true
        },
        {
          text: 'File Name',
          value: 'filename',
          sortable: true
        },
        {
          text: 'File Size',
          value: 'filesize',
          sortable: true
        },
        {
          text: 'Operation',
          value: 'operation',
          sortable: true
        },
        {
          text: 'Status',
          value: 'status',
          sortable: true
        }
      ],
      recordOptions: [
        {
          label: 'View',
          icon: 'mdi-eye'
        },
        {
          label: 'Process',
          icon: 'mdi-reload-alert'
        }
      ]
    }
  },
  methods: {
    async optClick (event) {
      const eventName = event.eventName
      const eventData = event.eventData
      if (eventName === 'view') {
        const res = await ApiClient.get(`/transfer_log/view/${eventData.id}`)
        this.dialog_title = eventData.filename
        this.file_contents = 'loading...'
        this.dialog = true
        if (res.data.success) {
          this.file_contents = res.data.contents
        } else {
          this.dialog = false
          alert(res.data.message)
        }
      }
      if (eventName === 'process') {
        const res = await ApiClient.get(`/transfer_log/parse/${eventData.id}`)
        if (res.data.success) {
          this.$refs.listing.refresh()
        } else {
          alert(res.data.message)
        }
      }
    }
  }
})
