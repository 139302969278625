
import Vue from 'vue'
import Apiclient from '@/plugins/apiclient'
import { format } from 'date-fns'

export default Vue.extend({
  name: 'PurchaseOrderView',
  data () {
    return {
      orderInfo: null,
      currentTab: 0,
      opened: [],
      apidata: [],
      apidatahistory: [],
      search: ''
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'SKU',
          align: 'start',
          sortable: true,
          value: 'item_sku'
        },
        {
          text: 'Vendor SKU',
          value: 'item_mpn'
        },
        {
          text: 'Name',
          value: 'item_name'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Received',
          value: 'received'
        },
        {
          text: 'Ordered',
          value: 'quantity'
        },
        {
          text: 'Progress',
          sortable: false,
          value: 'progress'
        }
      ]
    },
    headersHistory () {
      return [
        {
          text: 'Date',
          align: 'start',
          sortable: true,
          value: 'date'
        },
        {
          text: 'Source',
          value: 'source'
        },
        {
          text: 'Action',
          value: 'action'
        },
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'Direction',
          value: 'direction',
          icon: 'mdi-factory'
        },
        {
          text: 'File Name',
          value: 'filename'
        },
        {
          text: 'Old Status',
          value: 'old_status'
        },
        {
          text: 'New Status',
          value: 'new_status'
        },
        {
          text: 'Notes',
          value: 'notes'
        }
      ]
    },
    headersSerials () {
      return [
        {
          text: 'Order Number',
          align: 'start',
          sortable: true,
          value: 'number'
        },
        {
          text: 'Item UPC',
          value: 'item_upc'
        },
        {
          text: 'Item MPN',
          value: 'item_mpn'
        },
        {
          text: 'Item Name',
          value: 'item_name'
        },
        {
          text: 'Serial Number',
          value: 'serial_number'
        }
      ]
    }
  },
  methods: {
    formatDate (timestamp) {
      return format(
        new Date(timestamp * 1000),
        'yyyy-MM-dd HH:ss'
      )
    },
    toggle (id: number) {
      const arr = this.opened
      if (arr.includes(id)) {
        const index = arr.indexOf(id)
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    }
  },
  async beforeMount () {
    const orderId = this.$route.params.purchaseOrderId
    let res = await Apiclient.get(`/purchase-orders/${orderId}`)
    this.orderInfo = res.data
    res = await Apiclient.get(`/purchase-orders/${orderId}`)
    this.orderInfo.history = res.data.history
    this.apidata = this.orderInfo.items
    this.apidatahistory = this.orderInfo.history
    this.apidataserials = this.orderInfo.serial_numbers
  }
})
