class Cookie {
  create (token: string, expDateTimestamp: number) {
    const expDate = new Date(expDateTimestamp * 1000)
    const tempcookie = 'auth_token=' + token + ';expires=' + expDate.toUTCString() + ';path=/'
    document.cookie = tempcookie
  }

  get () {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      if (cookie.startsWith('auth_token=')) {
        const token = cookie.substring('auth_token='.length)
        return token
      }
    }
    return null // Return null if "auth_token" cookie is not found
  }

  delete () {
    document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;'
  }
}

export default new Cookie()
