import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'
import token from '@/plugins/token'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/take-picture']
  const authRequired = !publicPages.includes(to.path)
  let loggedIn = store.getters.getCurrentUser

  if (!loggedIn) {
    await token.check()
    loggedIn = store.getters.getCurrentUser
  }

  if (authRequired && !loggedIn) {
    return next({
      path: '/login',
      query: {
        // 20220905 - ER. is it necessary?
        returnUrl: to.path
      }
    })
  } else if (!authRequired && loggedIn) { // Added so if user is logged in he can't access login page
    return next({
      path: '/'
    })
  }

  next()
})

export default router
