import store from '../store/index'
import Apiclient from '@/plugins/apiclient'
import cookie from './cookie'

class Authenticate {
  async authenticate (email: string, password: string) {
    // @todo get csrf token
    const csrf = await Apiclient.get('/csrf-token')
    const res = await Apiclient.post('/authenticate', { email, password }, csrf.data.token)
    if (res.data && res.data.result) {
      await store.dispatch('login', res.data.user)
      cookie.create(res.data.user.auth_token.token, res.data.user.auth_token.expiration_time)
      return true
    }
    return false
  }
}

export default new Authenticate()
