<template>
  <div class="kpi-card">
    <v-card elevation="0">
      <v-card-title class="kpi-title">{{ title }}</v-card-title>
      <v-card-text v-if="chartData">
        <v-row align="center" class="mx-0">
          <div class="kpi-text">
            {{ chartData?.value }}
          </div>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'KpiCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    chartData: {
      type: Object,
      default: () => {
        return {
          value: 0
        }
      }
    }
  },
  data () {
    return {}
  }
}
</script>

<style scoped>
.kpi-text {
  font-size: 40pt;
  font-weight: bold;
  color: '#444444';
  line-height: 60pt;
  text-align: center;
  width: 100%;
}

.kpi-title {
  display: block;
  width: 100%;
  text-align: center;
}

.kpi-card {
  display: flex;
  height: 350px;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}
</style>
