
import Vue from 'vue'
export default Vue.extend({
  name: 'BreadCrumb',
  computed: {
    breadCrumbs () {
      if (typeof this.$route?.meta?.breadCrumb === 'function') {
        return this.$route.meta.breadCrumb.call(this, this.$route)
      }
      return this.$route?.meta?.breadCrumb
    }
  }
})
