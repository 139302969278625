<template>
  <Pie
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :title="title"
    :width="size.width"
    :height="size.height"
  />
</template>

<script>
import { Pie } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: 'PieChart',
  components: {
    Pie
  },
  props: {
    chartId: {
      type: String,
      default: 'pie-chart'
    },
    chartData: {
      labels: {
        type: Array,
        default: () => []
      },
      datasets: [
        {
          backgroundColor: {
            type: String,
            default: '#f87979'
          },
          data: {
            type: Array,
            default: () => []
          }
        }
      ]
    },
    title: {
      default: '',
      type: String
    },
    size: {
      type: Object,
      default: () => {
        return {
          width: 300,
          height: 300
        }
      }
    }
  },
  data () {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  }
}
</script>
