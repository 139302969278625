
import Vue from 'vue'
import BaseList from '@/components/lists/BaseList.vue'
import Config from '@/plugins/config'
import ApiClient from '@/plugins/apiclient'

export default Vue.extend({
  name: 'SerialNumbers',
  components: { BaseList },
  data () {
    return {
      listingButtons: [
        {
          label: 'Download',
          icon: 'mdi-download'
        }
      ],
      items: [],
      headers: [
        {
          text: 'Serial Number',
          value: 'serial_number',
          sortable: true,
          searchable: true
        },
        {
          text: 'Order number',
          value: 'number',
          sortable: true
        },
        {
          text: 'SKU',
          value: 'item_sku',
          sortable: true
        },
        {
          text: 'MPN',
          value: 'item_mpn',
          sortable: true
        },
        {
          text: 'UPC',
          value: 'item_upc',
          sortable: true
        },
        {
          text: 'Part Name',
          value: 'item_name',
          sortable: true
        },
        {
          text: 'Date',
          value: 'created_at',
          sortable: true
        }
      ],
      menustart: false,
      menuend: false,
      startdate: null,
      enddate: null
    }
  },
  methods: {
    async resetDates () {
      this.startdate = null
      this.enddate = null
      const res = await ApiClient.get('/serial_numbers')
      this.$refs.listing.items = res.data
    },

    async filterByDate () {
      if (!this.startdate || !this.enddate) {
        alert('Please enter both values for start and end date!')
        this.startdate = null
        this.enddate = null
      } else {
        const res = await ApiClient.get(this.startdate || this.enddate ? `/serial_numbers?start=${Date.parse(this.startdate) / 1000}&end=${(Date.parse(this.enddate) / 1000) + 86399}` : '/serial_numbers') // This +86399 is for adding extra 23:59:59h to timestamp for enddate
        this.$refs.listing.items = res.data
      }
    },

    buttonClick (evt) {
      if (evt.eventName === 'download') {
        window.open(this.startdate || this.enddate ? `${Config.apiUrl}/serial_numbers_download?start=${Date.parse(this.startdate) / 1000}&end=${(Date.parse(this.enddate) / 1000) + 86399}` : `${Config.apiUrl}/serial_numbers_download`) // This +86399 is for adding extra 23:59:59h to timestamp for enddate
      }
    }
  }
})
