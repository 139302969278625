import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import * as Sentry from '@sentry/vue'
// import { BrowserTracing } from '@sentry/tracing'

Vue.config.productionTip = false

Vue.use(VueAxios, axios)

// Sentry.init({
//   Vue,
//   dsn: 'https://a4049e3395cc4a40bfc318a5849f8da0@o1410949.ingest.sentry.io/6748704',
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ['localhost', 'edi.zanders.com', 'edi-stg.zanders.com', /^\//]
//     })
//   ],
//   tracesSampleRate: 1.0
// })

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
