
import Vue from 'vue'
import LoginForm from '@/components/forms/LoginForm'
import AuthClient from '@/plugins/auth'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'LoginView',
  components: { LoginForm },
  methods: {
    async login (credentials: Record<string, string>) {
      const res = await AuthClient.authenticate(credentials.email, credentials.password)
      if (res) {
        this.$router.push('/')
      } else {
        this.setError({
          message: 'Invalid Credentials'
        })
      }
    },

    ...mapActions(['setError'])
  }
})
