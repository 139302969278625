
import Vue from 'vue'
import { BtnInterface } from '@/lib/interfaces'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'UserForm',
  props: {
    buttons: {
      type: Array,
      default: () : Array<BtnInterface> => []
    }
  },
  data () {
    return {
      formData: {},
      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid email.'
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getFormRecord'
    ])
  },

  mounted () {
    this.formData = this.getFormRecord
    if (typeof (this.formData) === 'undefined') {
      this.formData = {
        name: '',
        password: '',
        email: '',
        enabled: false
      }
    }
    if (typeof (this.formData.enabled) === 'undefined') {
      this.formData.enabled = false
    }
  },

  methods: {
    triggerButton (button: BtnInterface) {
      if (button.autoClose) {
        this.hideModal()
      } else {
        if (this.$refs.form.validate()) {
          this.$emit('btnClick', { button, data: this.formData })
        }
      }
    },

    ...mapActions(['hideModal'])
  }
})
