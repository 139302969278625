
import Vue from 'vue'
import BaseList from './BaseList.vue'

export default Vue.extend({
  name: 'PurchaseOrdersList',
  components: { BaseList },
  data () {
    return {
      headers: [
        {
          text: 'Id',
          value: 'id',
          sortable: true
        },
        {
          text: 'Date',
          value: 'generated_on',
          sortable: true
        },
        {
          text: 'Level',
          value: 'level',
          sortable: true
        },
        {
          text: 'Message',
          value: 'message',
          sortable: false
        }
      ],
      buttons: [],
      'record-options': []
    }
  }
})
