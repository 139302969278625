<template>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :width="size.width"
    :height="size.height"
  />
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

export default {
  name: 'LineChart',
  components: {
    LineChartGenerator
  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    chartData: {
      labels: {
        type: Array,
        default: () => []
      },
      datasets: [
        {
          label: {
            type: String,
            default: ''
          },
          backgroundColor: {
            type: String,
            default: '#333333'
          },
          data: {
            type: Array,
            default: () => []
          }
        }
      ]
    },
    title: {
      default: '',
      type: String
    },
    size: {
      type: Object,
      default: () => {
        return {
          width: 300,
          height: 300
        }
      }
    }
  },
  data () {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  }
}
</script>
