<template>
  <applications-list />
</template>

<script>
import ApplicationsList from '@/components/lists/ApplicationsList'

export default {
  name: 'ApplicationsPage',
  components: {
    ApplicationsList
  }
}
</script>

<style scoped>

</style>
