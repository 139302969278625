import { render, staticRenderFns } from "./ElliottFiles.vue?vue&type=template&id=a404e6a0&scoped=true&"
import script from "./ElliottFiles.vue?vue&type=script&lang=ts&"
export * from "./ElliottFiles.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a404e6a0",
  null
  
)

export default component.exports