import config from './config'
import axios, { AxiosResponse } from 'axios'
import token from './token'

class ApiClient {
  baseUrl = ''
  apiKey = ''

  constructor (baseUrl: string, apiKey: string) {
    this.baseUrl = baseUrl
    this.apiKey = apiKey
  }

  fixPath (path: string): string {
    path = path.trim()
    if (path[0] !== '/') {
      path = '/' + path
    }

    return path
  }

  getApiKeyHeader () : Record<string, string> {
    return {
      'x-api-key': this.apiKey,
      token: token.get()
    }
  }

  async get (path: string, params: Record<string, unknown> = {}): Promise<AxiosResponse> {
    const url = this.baseUrl
    const headers = this.getApiKeyHeader()
    path = this.fixPath(path)

    return await axios.get(url + path, { params, headers })
  }

  async download (path: string, params: Record<string, unknown> = {}): Promise<AxiosResponse> {
    const url = this.baseUrl
    const headers = this.getApiKeyHeader()
    path = this.fixPath(path)

    return await axios.get(url + path, { params, headers, responseType: 'blob' })
  }

  async post (path: string, params: Record<string, unknown> | null = null, csrfToken: string | null = null): Promise<AxiosResponse> {
    const url = this.baseUrl
    const headers = this.getApiKeyHeader()
    if (csrfToken) {
      headers['X-CSRF-Token'] = csrfToken
    }
    path = this.fixPath(path)
    return await axios.post(url + path, params, { headers })
  }

  async put (path: string, params: Record<string, unknown>): Promise<AxiosResponse> {
    const url = this.baseUrl
    const headers = this.getApiKeyHeader()

    return await axios.put(url + path, params, { headers })
  }

  async delete (path: string): Promise<AxiosResponse> {
    const url = this.baseUrl
    const headers = this.getApiKeyHeader()
    path = this.fixPath(path)

    return await axios.delete(url + path, { headers })
  }
}

export default new ApiClient(
  config.apiUrl,
  config.apiKey
)
