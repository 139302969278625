
import Vue from 'vue'

import BarChart from './BarWidget.vue'
import PieChart from './PieWidget.vue'
import LineChart from './LineWidget.vue'
import KpiCard from './KpiCardWidget.vue'

export default Vue.extend({
  name: 'BaseWidget',
  components: {
    BarChart,
    PieChart,
    LineChart,
    KpiCard
  },
  props: {
    widget: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {}
  }
})
