
import Vue from 'vue'
import { BtnInterface } from '@/lib/interfaces'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'LocationForm',
  props: {
    buttons: {
      type: Array,
      default: () : Array<BtnInterface> => []
    }
  },
  data () {
    return {
      locationTypes: [
        {
          label: 'Bill To',
          value: 'bill_to'
        },
        {
          label: 'Ship To',
          value: 'ship_to'
        }
      ],
      rules: {
        required: value => !!value || 'Required.'
      }
    }
  },
  computed: {
    ...mapGetters({
      formData: 'getLocationFormRecord'
    })
  },
  methods: {
    triggerButton (button: BtnInterface) {
      if (button.autoClose) {
        this.hideModal()
        this.$emit('btnClick', { button })
      } else {
        if (this.$refs.form.validate()) {
          this.formData.zip_code = Number(this.formData.zip_code)
          this.$emit('btnClick', { button, data: this.formData })
        }
      }
    },

    ...mapActions(['hideModal'])
  }
})
