
import Vue from 'vue'
import BaseList from '@/components/lists/BaseList.vue'
import ModalForm from '@/components/common/ModalForm.vue'
import UserForm from '@/components/forms/UserForm.vue'
import { mapActions } from 'vuex'
import Apiclient from '@/plugins/apiclient'
import { BtnEventInterface, BtnEventNameInterface } from '@/lib/interfaces'
import messages from '@/lang/en.json'
import cookie from '@/plugins/cookie'

export default Vue.extend({
  name: 'UsersList',
  components: { BaseList, ModalForm, UserForm },
  data () {
    return {
      buttons: [
        {
          icon: 'mdi-plus',
          label: 'New User',
          id: 'userslist-button-new'
        }
      ],
      headers: [
        {
          text: 'Id',
          value: 'id',
          sortable: true
        },
        {
          text: 'Name',
          value: 'name',
          sortable: true
        },
        {
          text: 'E-mail',
          value: 'email',
          sortable: true
        },
        {
          text: 'Enabled',
          value: 'enabled',
          sortable: false
        }
      ],
      formButtons: [
        {
          label: 'Cancel',
          id: 'userform-button-cancel',
          outlined: true,
          autoClose: true
        },
        {
          label: 'Save',
          id: 'userform-button-save'
        }
      ],
      recordOptions: [
        {
          icon: 'mdi-pencil',
          label: 'Edit'
        }
      ]
    }
  },
  methods: {
    btnClick (btnEvent) {
      switch (btnEvent.eventName) {
        case 'new_user': {
          this.clearModalRecord()
          this.showModal()
        }
      }
    },

    async buttonClick (eventData: BtnEventInterface) {
      if (eventData.button.label === 'Save') {
        try {
          if (!eventData.data.id) {
            await Apiclient.post('/users', eventData.data)
          } else {
            await Apiclient.put(`/users/${eventData.data.id}`, eventData.data)
          }
          const ref = this.$refs.listing as unknown
          ref.refresh()
          this.hideModal()
        } catch (e) {
          this.setError(e)
        }
      }
    },

    async optClick (eventInfo: BtnEventNameInterface) {
      if (eventInfo.eventName === 'edit') {
        this.setModalFormRecord(eventInfo.eventData)
        this.showModal()
      }
      if (eventInfo.eventName === 'delete') {
        if (confirm('Do you want to delete this record')) {
          try {
            await Apiclient.delete(`/users/${eventInfo.eventData.id}`)
            cookie.delete()
            const ref = this.$refs.listing as unknown
            ref.refresh()
          } catch (e) {
            this.setError(e)
          }
        }
      }
    },
    getMessage (key:string): string {
      return messages[key]
    },
    ...mapActions(['showModal', 'hideModal', 'clearModalRecord', 'setModalFormRecord', 'setError'])
  }
})
