
import Vue from 'vue'
import ErrorBar from '@/components/common/ErrorBar.vue'
import BreadCrumb from '@/components/common/BreadCrumb.vue'
import apiclient from '@/plugins/apiclient'

export default Vue.extend({
  name: 'App',
  components: { ErrorBar, BreadCrumb },

  data: () => ({
    drawer: false,
    darkMode: false,
    navLinks: [
      {
        icon: 'mdi-view-dashboard',
        text: 'Dashboard',
        link: '/'
      },
      {
        text: 'separator'
      },
      {
        icon: 'mdi-file-document',
        text: 'Purchase Orders',
        link: '/purchase-orders'
      },
      {
        icon: 'mdi-folder',
        text: 'Elliott files',
        link: '/elliott-files'
      },
      {
        icon: 'mdi-factory',
        text: 'Trading Partners',
        link: '/trading-partners'
      },
      {
        icon: 'mdi-apps',
        text: 'Applications',
        link: '/applications'
      },
      {
        icon: 'mdi-account-multiple',
        text: 'Users',
        link: '/users'
      },
      {
        icon: 'mdi-swap-vertical-bold',
        text: 'Transfer Log',
        link: '/transfer-log'
      },
      {
        icon: 'mdi-vector-union',
        text: 'Reconciliation',
        link: '/reconciliation'
      },
      {
        icon: 'mdi-barcode',
        text: 'Serial Numbers',
        link: '/serial-numbers'
      },
      {
        text: 'separator'
      },
      {
        icon: 'mdi-key',
        text: 'Change password',
        link: '/change-password'
      },
      {
        icon: 'mdi-bell-outline',
        text: 'Notifications',
        link: '/notifications'
      },
      {
        icon: 'mdi-cog',
        text: 'Settings',
        link: '/settings'
      },
      {
        text: 'separator'
      },
      {
        icon: 'mdi-book',
        text: 'Documentation',
        link: '/docs'
      }
    ]
  }),

  methods: {
    toggleDarkMode () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    },
    async logout () {
      await apiclient.get('logout')
      this.$store.dispatch('logout')
      window.location.href = '/'
    }
  }
})
