
import Vue from 'vue'

export default Vue.extend({
  name: 'LoginForm',
  data () {
    return {
      username: '',
      password: '',
      isValid: false
    }
  },
  methods: {
    login () {
      this.$emit('login', { email: this.username, password: this.password })
    },

    checkInputs () {
      this.username = this.username.trim()
      this.password = this.password.trim()
      if (this.username.length && this.password.length) {
        this.isValid = true
        return
      }
      this.isValid = false
    }
  }
})
