import { render, staticRenderFns } from "./ApplicationsList.vue?vue&type=template&id=0897b6a6&scoped=true&"
import script from "./ApplicationsList.vue?vue&type=script&lang=ts&"
export * from "./ApplicationsList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0897b6a6",
  null
  
)

export default component.exports