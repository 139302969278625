import { render, staticRenderFns } from "./UsersView.vue?vue&type=template&id=fbd6c0c4&scoped=true&"
import script from "./UsersView.vue?vue&type=script&lang=ts&"
export * from "./UsersView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbd6c0c4",
  null
  
)

export default component.exports