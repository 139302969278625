
import Vue from 'vue'
import { BtnInterface } from '@/lib/interfaces'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'ApplicationForm',
  props: {
    buttons: {
      type: Array,
      default: () : Array<BtnInterface> => []
    }
  },
  data () {
    return {
      formData: {},
      rules: {
        required: value => !!value || 'Required.'
      }
    }
  },

  computed: {
    ...mapGetters([
      'getFormRecord'
    ])
  },

  mounted () {
    this.formData = this.getFormRecord
    if (typeof (this.formData) === 'undefined') {
      this.formData = {
        name: '',
        apiKey: '',
        description: '',
        enabled: false
      }
    }
    if (typeof (this.formData.enabled) === 'undefined') {
      this.formData.enabled = false
    }
  },

  methods: {
    async triggerButton (button: BtnInterface) {
      if (button.autoClose) {
        this.hideModal()
      } else {
        const valid = await this.$refs.form.validate()
        if (!valid) {
          return
        }
        this.$emit('btnClick', { button, data: this.formData })
      }
    },

    ...mapActions(['hideModal'])
  }
})
