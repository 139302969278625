<template>
  <purchase-order-list />
</template>

<script>
import PurchaseOrderList from '@/components/lists/PurchaseOrdersList.vue'

export default {
  name: 'PurchaseOrdersPage',
  components: {
    PurchaseOrderList
  }
}
</script>

<style scoped>

</style>
