
import Vue from 'vue'
import BaseList from '@/components/lists/BaseList.vue'
import ApiClient from '@/plugins/apiclient.ts'

export default Vue.extend({
  name: 'ReconciliationView',
  components: { BaseList },
  data: () => {
    return {
      headers: [
        {
          text: 'Date',
          value: 'created_at'
        },
        {
          text: 'Order Number',
          value: 'number'
        },
        {
          text: 'Item Name',
          value: 'item_name'
        },
        {
          text: 'UPC',
          value: 'item_upc'
        },
        {
          text: 'SKU',
          value: 'item_sku'
        },
        {
          text: 'Old Qty',
          value: 'old_data.quantity'
        },
        {
          text: 'Received Qty',
          value: 'received'
        },
        {
          text: 'New Qty',
          value: 'new_data.quantity'
        }
      ],
      recordOptions: [
        {
          label: 'View Order',
          icon: 'mdi-eye'
        }
      ],
      menustart: false,
      menuend: false,
      startdate: null,
      enddate: null
    }
  },
  methods: {
    optClick (eventInfo) {
      try {
        this.$router.push({
          name: 'purchase-order-detail',
          params: {
            purchaseOrderId: eventInfo.eventData.order_id
          }
        })
      } catch (e) {
        console.error(e)
      }
      this.$router.push(`/purchase-orders/${event.eventData.order_id}`)
      console.log(event)
    },

    async refresh () {
      const res = await ApiClient.get(`/reconciliation?start=${this.startdate}&end=${this.enddate}`)
      this.$refs.listing.items = res.data
    }
  }
})
