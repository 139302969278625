
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'ErrorBar',
  data: () => {
    return {
      isError: false
    }
  },
  computed: {
    ...mapGetters(['getCurrentError'])
  },
  watch: {
    isError (val, oldVal) {
      if (oldVal && !val) {
        this.setError(null)
      }
    },

    getCurrentError (val) {
      if (val !== null) {
        this.isError = true
      }
    }
  },
  methods: {
    dismiss () {
      this.isError = false
    },

    ...mapActions(['setError'])
  }
})
