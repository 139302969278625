
import BaseWidget from '@/components/widgets/dashboard/BaseWidget.vue'
import Apiclient from '@/plugins/apiclient'
import Vue from 'vue'

export default Vue.extend({
  name: 'HomeView',
  components: {
    BaseWidget
  },
  data () {
    return {
      user: null,
      pageTitle: 'Dashboard',
      kpiCardTitle: '',
      kpiCardValue: 0,
      widgets: {}
    }
  },
  methods: {
    async getChartData () {
      const response = await Apiclient.get('/dashboard')
      if (response.status === 200) {
        const data = response.data

        this.widgets = data
      }
    }
  },
  mounted () {
    this.getChartData()
  }
})
